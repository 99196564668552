import React from "react"
import Layout from "@yumgmbh/gatsby-theme-yum-components/src/components/modules/Layout"
import BodyBlocks from "@yumgmbh/gatsby-theme-yum-components/src/components/technical/BodyBlocks"
import { graphql } from "gatsby"

const Produkte = ({ data }) => {
  const collections = data.allShopifyCollection.edges

  // remove collections with no products in stock
  const filteredCollections = collections.filter((collection) => {
    return collection.node.products.some(
      (product) => product.totalInventory > 0
    )
  })

  const body = [
    {
      component: "cards_block",
      headline: "Produktbereiche",
      view: "grid",
      show_slides: "2",
      card_items: filteredCollections.map((collection) => {
        return {
          image: {
            filename: collection.node.image
              ? collection.node.image.src
              : process.env.GATSBY_IMG_PLACEHOLDER,
            alt: collection.node.title,
          },
          headline: collection.node.title,
          text: collection.node.description,
          link: `/de-de/produkte/${collection.node.handle}`,
          link_text: "Mehr erfahren",
        }
      }),
    },
  ]

  return (
    <Layout language="de">
      <BodyBlocks body={body} />
    </Layout>
  )
}

export const query = graphql`
  query AllCollectionsQuery {
    allShopifyCollection(sort: { fields: metafields___value, order: ASC }) {
      edges {
        node {
          title
          handle
          description
          image {
            src
            altText
          }
          products {
            totalInventory
          }
        }
      }
    }
  }
`

export default Produkte
